<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('contractsAdd')"
          @click="$router.push('contracts/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('contractsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه العقود
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column field="id" header="رقم العقد" :sortable="true" />
      <Column field="clientsId.code" header="كود العميل" :sortable="true" />
      <Column field="clientsId.name" header="اسم العميل" :sortable="true" />
      <Column field="branchesId.name" header="اسم الفرع" :sortable="true" />
      <Column field="date" header="تاريخ العقد" :sortable="true" />
      <Column field="total" header="قيمه العقد" :sortable="true" />

      <Column field="deliveryDate" header="اقصى تاريخ تسليم" :sortable="true" />
      <Column field="status" header="الحاله" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.status }}
        </template>
        
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <!-- <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Button
                  type="button"
                  icon="pi pi-trash" v-tooltip="'حذف'"
                  @click="slotProps2.data.id"
                  class="p-button-warning  p-button-rounded"
                ></Button>
              </template>
            </Column> -->
          </DataTable>
          <DataTable
            :value="slotProps.data.payments"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه الدفعات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="id" header="الكود" />
            <Column field="name" header="الاسم" />
            <Column field="price" header="السعر" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <span v-if="slotProps2.data.paymentType">
                  تم الاستلام بوسطه
                  {{
                    slotProps2.data.usersId
                      ? slotProps2.data.usersId.name
                      : '--'
                  }}
                </span>
                <span v-else>
                  لم يسدد
                </span>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      displayBasicToInstalltions: false,
      expandedRows: [],
      loading: true,
    };
  },
  methods: {
    getData() {
      const user = JSON.parse(localStorage.user);

      this.$http.get(`contracts/getContractsByClientsId/${user.id}`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
